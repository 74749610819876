<template>
    <div>
        <div class="default_box" v-if="homeData">
            <router-link :to="{name:'SharesList'}">
                <div class="default_header">
                    <h2>Акции</h2>
                </div>
            </router-link>
            <div class="default_box_content container-fluid">
                <b-row>
                    <b-col cols="12" md="4" lg="4" v-for="item in homeData" :key="item.id" class="home_item_box">
                        <router-link :to="{name:'Shares',params: { id:item.id }}">
                            <div class="home_item">
                                <div v-if="$mobileWidth()">
                                    <img :src="item.image ? item.image : '/images/default_image.jpg'" alt="image">
                                </div>
                                <div v-else class="home_item_image_box" :style="'background-image: url('+(item.image ? item.image : '/images/default_image.jpg') +')'"></div>
                                <div class="home_item_title" v-html="$cutText(item.name, limit)"></div>
                                <div class="home_item_footer">
                                    <div v-if="item.start_at" v-html="$moment(item.start_at).local().format('начало с DD.MM.YYYY')"></div>
                                    <div class="home_item_comment_box">
                                        <div v-if="item.comments_count" class="home_item_comment_count" v-html="item.comments_count"></div>
                                        <div class="icon_comment"></div>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SharesPlagin",
    props: {
        homeData: Array
    },
    data() {
        return {
            limit: 65
        }
    },

}
</script>
<style scoped>
@media (min-width: 768px) {
    .default_box .default_header:hover {
        background: rgba(74, 118, 168, 0.1);
        transition: .3s;
    }
}
</style>