<template>
    <div>
        <SearchPlagin v-if="$averageWidth()" cat_search="All" info="Поиск по сайту" />
        <div v-if="preloader">
            <div v-if="homeData">
                <NewsPlagin v-if="homeData && homeData.news && homeData.news.length" :homeData="homeData.news" />
                <PosterPlagin v-if="homeData && homeData.poster && homeData.poster.length" :homeData="homeData.poster" />
                <SharesPlagin v-if="homeData && homeData.shares && homeData.shares.length" :homeData="homeData.shares" />
<!--                <CommunicationPlagin v-if="homeData.communication && homeData.communication.length" :homeData="homeData.communication" />-->
                <!-- <Buttons v-if="homeData.buttons && homeData.buttons.length" :homeData="homeData.buttons" /> -->
                <OrgPlagin />
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
import NewsPlagin from "./NewsPlagin";
import PosterPlagin from "./PosterPlagin";
import SharesPlagin from "./SharesPlagin";
import CommunicationPlagin from "./CommunicationPlagin";
import SearchPlagin from '@/components/Plagins/SearchPlagin';
/*
import Buttons from "./Buttons";*/
import OrgPlagin from "./OrganisationPlagin";

export default {
    name: "Home",
    metaInfo() {
        return {
            title: this.homeData && Object.keys(this.homeData).length ? `Информационный сайт города - ` : null,
            meta: [
                {name: 'description', itemprop: 'description', content: `На страницах информационного портала Окей Город ${this.$myCity.name} Вы найдете главные новости, афишу кино и других мероприятий, акции и скидки, интересное общение, справочник организаций, работу и много другое в городе ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Информационный сайт города - ${this.$myCity.name}`},
                {property: 'og:description', content: `На страницах информационного портала Окей Город ${this.$myCity.name} Вы найдете главные новости, афишу кино и других мероприятий, акции и скидки, интересное общение, справочник организаций, работу и много другое в городе ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    components: {
        NewsPlagin,
        PosterPlagin,
        SharesPlagin,
        CommunicationPlagin,
        SearchPlagin,
        /*Buttons,*/
        OrgPlagin
    },
    data() {
        return {
            homeData: null,
            preloader: null,
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api: function() {
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Home.get';
            this.$http.get(url).then((response) => {
                // eslint-disable-next-line no-console
                this.homeData = response.data.response.data;
            }).finally(() => {
                this.preloader = 'finish';
            })
        },
    },

}
</script>
<style scoped>
</style>