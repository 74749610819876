<template>
    <div>
        <div class="default_box">
            <router-link :to="{name:'OrganisationCat'}">
                <div class="default_header">
                    <h2>Организации</h2>
                </div>
            </router-link>
            <div class="default_box_content container-fluid bg_white p15">
                <b-row>
                    <b-col cols="4" sm="4" lg="2" class="buttons_body" v-for="item in list" :key="item.id">
                        <router-link class="item" :to="{name:(item.category_children_count>0)?'OrganisationCat':'OrganisationList' ,params: { id:item.id }}">
                            <div class="img_box">
                                <img :src="item.icon" alt="icon" v-if="item.icon">
                                <img src="/images/icon/monuments.svg" alt="icon" v-else>
                            </div>
                            <span class="title">{{item.name}}</span>
                        </router-link>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "OrgPlagin",
    data() {
        return {
            list: [],
        }
    },
    methods: {
        api() {
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Organizations.getList';
            this.$http.get(url).then((response) => {
                this.list = response.data.response.category_list;
            })
        },
    },
    mounted() {
        this.api();
    }
}
</script>
<style scoped>
.buttons_body {
    padding: 5px;
    border-radius: 0.25rem;
    z-index: 10000px;
}

.buttons_body a {
    text-decoration: none;
    color: #282828;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
}

.buttons_body:hover {
    transform: scale(1.1);
    transition: .3s;

}

.buttons_body:hover a {
    color: #282828;
    transition: .3s;
}


.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 64px;
    margin-bottom: 5px;
    padding: 3px;
}

@media (min-width: 768px) {
    .default_box .default_header:hover {
        background: rgba(74, 118, 168, 0.1);
        transition: .3s;
    }
}
</style>