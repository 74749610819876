<template>
    <div>
        <div class="default_box" v-if="homeData">
            <router-link :to="{name:'CommunicationList'}">
                <div class="default_header">
                    <h2>Городская лента</h2>
                </div>
            </router-link>
            <div class="default_box_content container-fluid">
                <b-row>
                    <b-col cols="12" md="4" lg="4" v-for="item in homeData" :key="item.id" class="home_item_box">
                        <router-link :to="{name:'CommunicationGet',params: { id:item.id }}">
                            <div class="home_item">
                                <div v-if="item.image">
                                    <div v-if="$mobileWidth()">
                                        <img :src="item.image" alt="image">
                                    </div>
                                    <div v-else class="home_item_image_box" :style="'background-image: url('+item.image+')'"></div>
                                </div>
                                <div class="home_item_title" v-html="$cutText(item.text, limit)"></div>
                                <div class="home_item_footer">
                                    <div v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:mm')"></div>
                                    <div class="home_item_comment_box">
                                        <div v-if="item.comments_count" class="home_item_comment_count" v-html="item.comments_count"></div>
                                        <div class="icon_comment"></div>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CommunicationPlagin",
    props: {
        homeData: Array
    },
    data() {
        return {
            limit: 65
        }
    },
    methods: {},
}
</script>
<style scoped>
@media (min-width: 768px) {
    .default_box .default_header:hover {
        background: rgba(74, 118, 168, 0.1);
        transition: .3s;
    }
}
</style>